import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MissedVideoCallIcon from '@material-ui/icons/MissedVideoCall';
import BarChartIcon from '@material-ui/icons/BarChart';
import InfographicCard from '../../components/Card/Card';
import DescriptionIcon from '@material-ui/icons/Description';
import LayersIcon from '@material-ui/icons/Layers';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { useDemoRouter } from '@toolpad/core/internal';
import ProjectList from '../../components/Projects/Projects';
import Videos from '../../components/Videos/Videos';

const NAVIGATION = [
    {
        kind: 'header',
        title: 'Main items',
    },
    {
        segment: 'dashboard',
        title: 'Dashboard',
        icon: <DashboardIcon />,
    },
    {
        segment: 'orders',
        title: 'Orders',
        icon: <ShoppingCartIcon />,
    },
    {
        segment: 'videos',
        title: 'Videos',
        icon: <MissedVideoCallIcon />,
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Analytics',
    },
    {
        segment: 'reports',
        title: 'Reports',
        icon: <BarChartIcon />,
        children: [
            {
                segment: 'sales',
                title: 'Sales',
                icon: <DescriptionIcon />,
            },
            {
                segment: 'traffic',
                title: 'Traffic',
                icon: <DescriptionIcon />,
            },
        ],
    },
    {
        segment: 'integrations',
        title: 'Integrations',
        icon: <LayersIcon />,
    },
];

const demoTheme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: true },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

function DemoPageContent({ pathname }) {
    const videos = [
        {
            description: "Criação de endpoint",
            link: 'https://www.loom.com/embed/e83c4e75a98e4ad38ee62fd6795b7906'
        },
        {
            description: "Correção de Campos e Modelos no Desenvolvimento",
            link: "https://www.loom.com/embed/65b6725b6efc42fd881b6821022b837d"
        }]
    const projects = [
        {
            name: "MForm",
            description: "Formulário para cadastro de integrantes da loja.",
            client: "Loja Maçonica",
            entregue: false,
            suporte: "3 meses"

        },
        {
            name: "Gadelha Team",
            description: "Plataforma para cadastro e gerenciamento de alunos da escola de artes marciais Gadelha Team, com integração de pagamento.",
            client: "Gadelha Team",
            entregue: true,
            suporte: "12 meses"
        },

    ];
    if (pathname === "/dashboard") {
        return (
            <Box
                sx={{
                    py: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px" }}>
                    <InfographicCard
                        icon="📈"
                        title="Projetos Concluídos"
                        value="0"
                        chart={<div>Gráfico aqui</div>}
                    />
                    <InfographicCard
                        icon="👥"
                        title="Projetos Angariados"
                        value="1"
                        chart={<div>Gráfico aqui</div>}
                    />
                    <InfographicCard
                        icon="⭐"
                        title="Preço Médio do time Hora/Projeto"
                        value="R$ 19/R$ 2000"
                        description="Baseado na somatória do preço de hora de todos os devs"
                        chart={<div>Gráfico aqui</div>}
                    />
                </div>


            </Box>
        );

    }

    if (pathname === "/videos") {
        return (
            <Box
                sx={{
                    py: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >

                <h1 style={{ textAlign: "center", marginTop: "20px" }}>Lista de Projetos</h1>
                <div style={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px" }}>
                    <Videos videos={videos} />
                </div>


            </Box>
        );

    }

    if (pathname === "/orders") {
        return (
            <Box
                sx={{
                    py: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >

                <h1 style={{ textAlign: "center", marginTop: "20px" }}>Lista de Projetos</h1>
                <div style={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px" }}>
                    <ProjectList projects={projects} />
                </div>


            </Box>
        );

    }


    return (
        <Box
            sx={{
                py: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
        </Box>
    )

}

DemoPageContent.propTypes = {
    pathname: PropTypes.string.isRequired,
};

function DashboardLayoutBasic(props) {
    const { window } = props;

    const router = useDemoRouter('/dashboard');

    // Remove this const when copying and pasting into your project.
    const demoWindow = window !== undefined ? window() : undefined;

    return (
        // preview-start
        <AppProvider
            navigation={NAVIGATION}
            router={router}
            theme={demoTheme}
            window={demoWindow}
        >
            <DashboardLayout>
                <DemoPageContent pathname={router.pathname} />
            </DashboardLayout>
        </AppProvider>
        // preview-end
    );
}

DashboardLayoutBasic.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window: PropTypes.func,
};

export default DashboardLayoutBasic;