import React from "react";
import "./Projects.css";

const ProjectList = ({ projects }) => {
    return (
        <div className="project-list">
            {projects.map((project, index) => (
                <div className="project-card" key={index}>
                    <h3 className="project-title">{project.name}</h3>
                    <p className="project-description">{project.description}</p>
                    <p className="project-suporte"><b>Suporte:</b> {project.suporte}</p>
                    <p className="project-client"><b>Cliente:</b> {project.client}</p>
                    <p className="project-client"><b>Entregue:</b> {project.entregue ? 'true'  : 'false'}</p>
                </div>
            ))}
        </div>
    );
};

export default ProjectList;