import React from "react";
import './Automatizacoes.css';

const Automatizacoes = () => {
    return (
        <section id="description" className="site-description-container">
            <div className="site-description-content">
                {/* Texto à esquerda */}
                <div className="site-description-text-container">
                    <h1 className="site-description-title">Automatização com Software</h1>
                    <main>
                        <p>Somos especialistas em <strong>automatização de serviços com software</strong>, oferecendo soluções inovadoras para transformar a maneira como sua empresa opera. Nossa missão é otimizar processos, reduzir custos e aumentar a produtividade, utilizando tecnologias modernas e personalizadas para atender às necessidades do seu negócio.</p>
                        <h2>O que oferecemos:</h2>
                        <ul>
                            <li><strong>Desenvolvimento de softwares personalizados:</strong> Criamos ferramentas sob medida para automatizar tarefas repetitivas e complexas.</li>
                            <li><strong>Integração de sistemas:</strong> Conectamos diferentes plataformas e sistemas para garantir um fluxo de trabalho mais eficiente e integrado.</li>
                            <li><strong>Automação de processos empresariais (BPA):</strong> Identificamos e automatizamos processos-chave para melhorar a eficiência e a escalabilidade.</li>
                            <li><strong>Implementação de RPA (Robotic Process Automation):</strong> Utilizamos bots inteligentes para executar tarefas manuais de forma rápida e precisa.</li>
                            <li><strong>Análise e otimização de processos:</strong> Mapear e redesenhar fluxos de trabalho para identificar oportunidades de melhoria.</li>
                        </ul>
                        <p>Com uma equipe de especialistas e um compromisso com a inovação, entregamos soluções que simplificam o trabalho, liberam tempo para atividades estratégicas e promovem o crescimento sustentável do seu negócio.</p>
                        <p><span class="highlight">Transforme seu potencial operacional com nossas automações inteligentes!</span></p>
                    </main>
                </div>

                {/* Imagem à direita */}
                <div className="site-description-image-container">
                    <img
                        src="img/automatizacao.png"
                        alt="Ilustração sobre software"
                        className="site-description-image"
                    />
                </div>
            </div>
        </section>
    );
};

export default Automatizacoes;
