import React from "react";
import './Services.css';

const Services = () => {
    return (
        <section id="services" className="services-container">
            <h2 className="services-title">Serviços</h2>
            <div className="services-cards-container">
                {/* Card 1 */}
                <a href="/servico-desenvolvimento" className="services-card">
                    <img
                        src="img/desenvolvimento.png"
                        alt="Serviço 1"
                        className="services-image"
                    />
                    <p className="services-card-text">Desenvolvimento de Sites e Apps</p>
                </a>

                {/* Card 2 */}
                <a href="/servico-suporte" className="services-card">
                    <img
                        src="img/suporte.png"
                        alt="Serviço 2"
                        className="services-image"
                    />
                    <p className="services-card-text">Suporte</p>
                </a>

                {/* Card 3 */}
                <a href="/servico-automacoes" className="services-card">
                    <img
                        src="img/automatizacao.png"
                        alt="Serviço 3"
                        className="services-image"
                    />
                    <p className="services-card-text">Automatizações</p>
                </a>
            </div>
        </section>
    );
};

export default Services;
