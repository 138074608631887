import React, { useState } from "react";
import api from "../../hooks/api";

const Contact = () => {

  const [email, setEmail] = useState("")
  const [nome, setNome] = useState("")
  const [content, setContent] = useState("")

  const sendMail = async (e) => {
    e.preventDefault();

    await api.post("/mail/enviarEmail", {
      email: email, 
      nome: nome, 
      text: content,
    }).then(() =>  {
      alert("E-mail Enviado!")
    })
  }

  return (
    <section style={styles.container}>
      <h2 style={styles.title}>Contato</h2>
      <p style={styles.subtitle}>
        Entre em contato conosco para mais informações ou para solicitar nossos serviços.
      </p>
      <form style={styles.form}>
        <div style={styles.inputGroup}>
          <label htmlFor="name" style={styles.label}>
            Nome
          </label>
          <input onChange={(e) => setNome(e.target.value)}  type="text" id="name" name="name" style={styles.input} placeholder="Seu nome" />
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="email" style={styles.label}>
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            placeholder="Seu email"
          />
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="message" style={styles.label}>
            Mensagem
          </label>
          <textarea
            id="message"
            name="message"
            onChange={(e) => setContent(e.target.value)}
            style={styles.textarea}
            placeholder="Sua mensagem"
          ></textarea>
        </div>
        <button onClick={sendMail} style={styles.button}>
          Enviar
        </button>
      </form>
    </section>
  );
};

const styles = {
  container: {
    padding: "3rem 2rem",
    backgroundColor: "#222222",
    color: "#FFFFFF",
    textAlign: "center",
  },
  title: {
    fontSize: "2.5rem",
    marginBottom: "1rem",
    color: "#E2853F",
  },
  subtitle: {
    fontSize: "1.2rem",
    marginBottom: "2rem",
    color: "#CCCCCC",
  },
  form: {
    maxWidth: "600px",
    width: "85%",
    margin: " auto",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  label: {
    marginBottom: "0.5rem",
    fontSize: "1rem",
    color: "#E2853F",
  },
  input: {
    width: "100%",
    padding: "0.8rem",
    fontSize: "1rem",
    border: "1px solid #E2853F",
    borderRadius: "8px",
    backgroundColor: "#333333",
    color: "#FFFFFF",
  },
  textarea: {
    width: "100%",
    padding: "0.8rem",
    fontSize: "1rem",
    border: "1px solid #E2853F",
    borderRadius: "8px",
    backgroundColor: "#333333",
    color: "#FFFFFF",
    height: "120px",
    resize: "none",
  },
  button: {
    padding: "1rem 2rem",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#FFFFFF",
    backgroundColor: "#E2853F",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#d06e36",
  },
};

export default Contact;
