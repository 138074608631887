import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer/Footer";
import UserProfile from "../../components/UserProfile/UserProfile";

const user = {
    avatar: "https://static.vecteezy.com/system/resources/previews/005/129/844/non_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg",
    name: "João Silva",
    email: "joao.silva@example.com",
    bio: "Desenvolvedor Full Stack apaixonado por criar soluções digitais eficientes e inovadoras.",
};

const Profile = () => {
  return (
    <div>
      <Header />
      <UserProfile user={user} />
      <Footer />
    </div>
  );
};


export default Profile;