import React from "react";
import "./Team.css";

const Team = () => {
    return (
        <section id="team" className="team-container">
            <h2 className="team-title">Nossa Equipe</h2>
            <div className="team-cards-container">
                {/* Card 1 */}
                <div className="team-card">
                    <img
                        src="img/felps.jpg"
                        alt="Felipe Felps"
                        className="team-avatar"
                    />
                    <h3 className="team-name">Augusto "Felps" Felipe</h3>
                    <p className="team-role">Desenvolvedor Frontend</p>
                    <p className="team-description">
                        Especialista em interfaces modernas e responsivas, com experiência
                        em React e TailwindCSS.
                    </p>
                </div>

                {/* Card 2 */}
                <div className="team-card">
                    <img
                        src="img/diego-profile.jpg"
                        alt="Diego Axel"
                        className="team-avatar"
                    />
                    <h3 className="team-name">Diego Axel</h3>
                    <p className="team-role">Desenvolvedor Backend</p>
                    <p className="team-description">
                        Focado em criar APIs escaláveis e seguras, utilizando Node.js e
                        Python.
                    </p>
                </div>

                {/* Card 3 */}
                <div className="team-card">
                    <img
                        src="img/mario.jpg"
                        alt="Mário Soares"
                        className="team-avatar"
                    />
                    <h3 className="team-name">Mário Soares</h3>
                    <p className="team-role">Full Stack & Tech Lead</p>
                    <p className="team-description">
                        Líder técnico da nossa equipe, responsável por guiar o time de
                        desenvolvimento em direção às melhores práticas e soluções inovadoras.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Team;