import React from "react";
import './SiteDescription.css';

const SiteDescription = () => {
  return (
    <section id="description" className="site-description-container">
      <div className="site-description-content">
        {/* Texto à esquerda */}
        <div className="site-description-text-container">
          <h1 className="site-description-title">BarioTech Soluções</h1>
          <p className="site-description-text">
            Somos especializados no desenvolvimento de soluções de software
            personalizadas, projetadas para atender às necessidades específicas
            do seu negócio. Nosso objetivo é criar ferramentas que otimizem
            processos, aumentem a eficiência e proporcionem valor real.
          </p>
          <p className="site-description-text">
            Com uma equipe de desenvolvedores experientes, utilizamos as
            tecnologias mais avançadas para entregar soluções inovadoras e de
            alta qualidade. Seja um sistema web, aplicativo móvel ou integração
            de APIs, nós transformamos ideias em realidade.
          </p>
        </div>

        {/* Imagem à direita */}
        <div className="site-description-image-container">
          <img
            src="img/frontpage.jpg"
            alt="Ilustração sobre software"
            className="site-description-image"
          />
        </div>
      </div>
    </section>
  );
};

export default SiteDescription;
