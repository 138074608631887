import React from "react";
import Header from "../../components/Headers/Header";
import SiteDescription from "../../components/Sections/SectionDescription";
import Services from "../../components/Services/Services";
import Team from "../../components/Team/Team";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import Partners from "../../components/Partners/Partner";

const Home = () => {
  return (
    <div>
      <Header />
      <SiteDescription />
      <Services />
      <Contact />
      <Team />
      <Partners />
      <Footer />
    </div>
  );
};


export default Home;