import React from "react";
import './Desenvolvimento.css';

const Desenvolvimentos = () => {
    return (
        <section id="description" className="site-description-container">
            <div className="site-description-content">
                {/* Texto à esquerda */}
                <div className="site-description-text-container">
                    <h1 className="site-description-title">Desenvolvimento de Software</h1>
                    <main>
                        <p>Oferecemos serviços de <strong>desenvolvimento de sites e aplicativos</strong>, criando soluções digitais modernas, responsivas e personalizadas para destacar sua presença online e atender às necessidades do seu público.</p>
                        <h2>O que oferecemos:</h2>
                        <ul>
                            <li><strong>Desenvolvimento de sites:</strong> Criamos sites profissionais e responsivos que proporcionam uma experiência de usuário intuitiva e engajante, otimizados para SEO e compatíveis com todos os dispositivos.</li>
                            <li><strong>Criação de lojas virtuais:</strong> Desenvolvemos e-commerces robustos e personalizados, com integração de métodos de pagamento e ferramentas de gestão eficientes.</li>
                            <li><strong>Desenvolvimento de aplicativos:</strong> Projetamos e implementamos apps nativos e híbridos para iOS e Android, adaptados às necessidades do seu negócio.</li>
                            <li><strong>Design UX/UI:</strong> Oferecemos interfaces atraentes e funcionais, garantindo a melhor experiência para seus usuários.</li>
                            <li><strong>Manutenção e suporte:</strong> Fornecemos atualizações e suporte técnico contínuos para garantir o desempenho e a segurança dos seus sites e aplicativos.</li>
                        </ul>
                        <p>Combinando tecnologia de ponta, design inovador e um profundo entendimento do mercado, ajudamos sua empresa a conquistar relevância no ambiente digital e alcançar novos patamares de sucesso.</p>
                        <p><span class="highlight">Transforme suas ideias em soluções digitais excepcionais com nossa expertise!</span></p>
                    </main>
                </div>

                {/* Imagem à direita */}
                <div className="site-description-image-container">
                    <img
                        src="img/desenvolvimento.png"
                        alt="Ilustração sobre software"
                        className="site-description-image"
                    />
                </div>
            </div>
        </section>
    );
};

export default Desenvolvimentos;
