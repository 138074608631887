import React from "react";
import "./Partners.css";

const Partners = () => {
    return (
        <section id="partners" className="partners-container">
            <h2 className="partners-title">Nossos Parceiros</h2>
            <div className="partners-cards-container">
                {/* Parceiro 1 */}
                <div className="partners-card">
                    <img
                        src="img/partners.png"
                        alt="Parceiro 1"
                        className="partners-avatar"
                    />
                    <h3 className="partners-name">Aberto para parcerias</h3>
                    <p className="partners-description">
                       Comming soon...
                    </p>
                </div>

                {/* Parceiro 2 */}
                <div className="partners-card">
                    <img
                        src="img/veraq.jpg"
                        alt="Parceiro 2"
                        className="partners-avatar"
                    />
                    <h3 className="partners-name">VERAXQY UX/UI</h3>
                    <p className="partners-description">
                        Especialista em UX/UI, a VERAXQY cria interfaces intuitivas e experiências de usuário marcantes. Com foco em usabilidade e design moderno, ajudam empresas a oferecer produtos visuais impactantes e funcionais, encantando e fidelizando seus usuários.
                    </p>
                </div>

                {/* Parceiro 3 */}
                <div className="partners-card">
                    <img
                        src="img/partners.png"
                        alt="Parceiro 3"
                        className="partners-avatar"
                    />
                    <h3 className="partners-name">Aberto para parcerias</h3>
                    <p className="partners-description">
                        Comming soon....
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Partners;
