import React from "react";
import './Suporte.css';

const Suportes = () => {
    return (
        <section id="description" className="site-description-container">
            <div className="site-description-content">
                {/* Texto à esquerda */}
                <div className="site-description-text-container">
                    <h1 className="site-description-title">Suporte</h1>
                    <main>
                        <p>Entendemos que o desempenho e a estabilidade dos seus softwares são essenciais para o sucesso do seu negócio. Por isso, oferecemos serviços de <strong>suporte técnico especializado</strong> para garantir que suas soluções tecnológicas operem de forma eficiente e segura.</p>
                        <h2>Nossos serviços incluem:</h2>
                        <ul>
                            <li><strong>Monitoramento contínuo:</strong> Acompanhamos o desempenho dos sistemas em tempo real para identificar e corrigir problemas antes que eles impactem suas operações.</li>
                            <li><strong>Suporte técnico 24/7:</strong> Nossa equipe está disponível a qualquer hora para resolver dúvidas e solucionar incidentes com rapidez.</li>
                            <li><strong>Atualizações de software:</strong> Garantimos que suas aplicações estejam sempre atualizadas com as últimas tecnologias e padrões de segurança.</li>
                            <li><strong>Otimização de performance:</strong> Avaliamos e melhoramos o desempenho dos seus sistemas para atender às demandas crescentes do mercado.</li>
                            <li><strong>Gestão de incidentes:</strong> Fornecemos soluções rápidas e eficazes para minimizar o tempo de inatividade e o impacto no seu negócio.</li>
                            <li><strong>Consultoria técnica:</strong> Oferecemos orientações estratégicas para aprimorar e escalar suas soluções de software conforme suas necessidades evoluem.</li>
                        </ul>
                        <p>Com um time de especialistas dedicados e tecnologias avançadas, entregamos um suporte confiável que permite que você foque no que realmente importa: o crescimento do seu negócio.</p>
                        <p><span class="highlight">Conte conosco para manter seus sistemas sempre funcionando no mais alto nível!</span></p>
                    </main>
                </div>

                {/* Imagem à direita */}
                <div className="site-description-image-container">
                    <img
                        src="img/suporte.png"
                        alt="Ilustração sobre software"
                        className="site-description-image"
                    />
                </div>
            </div>
        </section>
    );
};

export default Suportes;
