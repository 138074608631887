import React from "react";
import "./Videos.css";

const Videos = ({ videos }) => {
    return (
        <div className="video-list">
            {videos.map((video, index) => (
                <div className="video-card" key={index}>
                    <h1>{video.description}</h1>
                    <iframe
                        src={video.link}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                </div>
            ))}
        </div>
    );
};

export default Videos;