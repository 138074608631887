import React, { useState } from "react";
import api from "../../hooks/api";
import { useNavigate } from "react-router-dom";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const login = async () => {
    await api.post("/auth/login", {
      email: email, 
      password: password
    }).then((user) =>  {
      localStorage.setItem('isLogged', true)
      localStorage.setItem('user', JSON.stringify(user.data))
      navigate("/")
    })
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setErrorMessage("Por favor, preencha todos os campos.");
      return;
    }

  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <h2 style={styles.title}>Login</h2>
        
        {errorMessage && <p style={styles.error}>{errorMessage}</p>}

        <div style={styles.inputContainer}>
          <label htmlFor="email" style={styles.label}>Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu email"
            style={styles.input}
          />
        </div>

        <div style={styles.inputContainer}>
          <label htmlFor="password" style={styles.label}>Senha</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Digite sua senha"
            style={styles.input}
          />
        </div>
        <div style={styles.inputContainer}>

        <button onClick={login} type="submit" style={styles.button}>Entrar</button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#222222",
    color: "#FFFFFF",
  },
  form: {
    backgroundColor: "#333333",
    padding: "4rem",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "60%",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },
  title: {
    textAlign: "center",
    color: "#E2853F",
    marginBottom: "1.5rem",
  },
  inputContainer: {
    marginBottom: "1rem",
  },
  label: {
    display: "block",
    fontSize: "1rem",
    marginBottom: "0.5rem",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "1rem",
    border: "2px solid #444444",
    borderRadius: "4px",
    backgroundColor: "#555555",
    color: "#FFFFFF",
  },
  button: {
    width: "100%",
    padding: "12px",
    fontSize: "1.1rem",
    backgroundColor: "#E2853F",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#D7752E",
  },
  error: {
    color: "#FF4747",
    textAlign: "center",
    marginBottom: "1rem",
  },
};

export default Login;