// src/components/InfographicCard.js
import React from "react";
import "./Card.css";

const InfographicCard = ({ icon, title, value, description, chart }) => {
    return (
        <div className="infographic-card">
            <div className="infographic-header">
                <div className="icon">{icon}</div>
                <h3>{title}</h3>
            </div>
            <div className="infographic-body">
                <h1 className="value">{value}</h1>
                <p>{description}</p>
                <div className="chart">{chart}</div>
            </div>
        </div>
    );
};

export default InfographicCard;
