import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer/Footer";
import Login from "../../components/Login-Form/Login";

const LoginPage = () => {
  return (
    <div>
      <Header />
      <Login />
      <Footer />
    </div>
  );
};


export default LoginPage;