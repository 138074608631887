import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer/Footer";
import Desenvolvimentos from "../../components/Desenvolvimento/Desenvolvimento";

const Desenvolvimento = () => {
  return (
    <div>
      <Header />
      <Desenvolvimentos />
      <Footer />
    </div>
  );
};


export default Desenvolvimento;