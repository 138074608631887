import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer/Footer";
import Automatizacoes from "../../components/Automatizacoes/Automatizacoes";

const Automacoes = () => {
  return (
    <div>
      <Header />
      <Automatizacoes />
      <Footer />
    </div>
  );
};


export default Automacoes;