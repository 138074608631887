import React from "react";

const Footer = () => {
  return (
    <footer style={styles.container}>
      <div style={styles.content}>
        {/* Redes sociais */}
        <div style={styles.tableWrapper}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Redes Sociais</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styles.tableCell}>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/733/733547.png"
                      alt="Facebook"
                      style={styles.iconImage}
                    />
                    Facebook
                  </a>
                </td>
              </tr>
              <tr>
                <td style={styles.tableCell}>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
                      alt="Twitter"
                      style={styles.iconImage}
                    />
                    Twitter
                  </a>
                </td>
              </tr>
              <tr>
                <td style={styles.tableCell}>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/733/733561.png"
                      alt="LinkedIn"
                      style={styles.iconImage}
                    />
                    LinkedIn
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Links para sessões */}
        <div style={styles.tableWrapper}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Links Rápidos</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styles.tableCell}>
                  <a href="#description" style={styles.link}>
                    Descrição
                  </a>
                </td>
              </tr>
              <tr>
                <td style={styles.tableCell}>
                  <a href="#services" style={styles.link}>
                    Serviços
                  </a>
                </td>
              </tr>
              <tr>
                <td style={styles.tableCell}>
                  <a href="#team" style={styles.link}>
                    Equipe
                  </a>
                </td>
              </tr>
              <tr>
                <td style={styles.tableCell}>
                  <a href="#contact" style={styles.link}>
                    Contato
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div style={styles.footerBar}>
        <p style={styles.footerText}>
          © 2024 BarioTech Soluções.  Todos os direitos reservados.
        </p>
      </div>
    </footer>
  );
};

const styles = {
  container: {
    backgroundColor: "#222222",
    color: "#FFFFFF",
    padding: "2rem",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  tableWrapper: {
    flex: "1 1 300px", // Responsividade (ajusta a largura)
    margin: "0 1rem",
    textAlign: "left",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    fontSize: "1.2rem",
    color: "#E2853F",
    textAlign: "left",
    marginBottom: "1rem",
  },
  tableCell: {
    padding: "0.5rem 0",
    fontSize: "1rem",
  },
  link: {
    textDecoration: "none",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  iconImage: {
    width: "20px",
    height: "20px",
    objectFit: "cover",
  },
  footerBar: {
    borderTop: "1px solid #E2853F",
    marginTop: "2rem",
    paddingTop: "1rem",
    textAlign: "center",
  },
  footerText: {
    fontSize: "0.9rem",
    color: "#CCCCCC",
  },
};

export default Footer;
