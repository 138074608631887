// src/components/UserProfile.js
import React from "react";
import "./UserProfile.css";


const UserProfile = () => {
    let user = JSON.parse(localStorage.getItem("user"))
    user.avatar = "https://static.vecteezy.com/system/resources/previews/005/129/844/non_2x/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg"
    return (
        <section className="user-container">
        <div className="user-profile">
            <img src={user.avatar} alt={`${user.user.name}'s avatar`} className="profile-avatar" />
            <h2 className="profile-name">{user.user.name}</h2>
        <div className="user-profile-left">

            <p className="profile-email"><b>E-mail: </b>{user.user.email}</p>
            <p className="profile-bio">{user.user.bio}</p>
            <p className="profile-bio"><b>Projetos Entregues:</b> 0</p>
            <p className="profile-bio"><b>Projetos em andamento:</b> 1</p>
            <p className="profile-bio"><b>Projetos em suporte:</b> 1</p>
        </div>
        </div>

        </section>
    );
};

export default UserProfile;
