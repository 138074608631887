import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import LoginPage from './pages/login';
import Automacoes from './pages/automacoes';
import Desenvolvimento from './pages/desenvolvimento';
import Suporte from './pages/suporte';
import Dashboard from './pages/dashboard';
import Profile from './pages/profile';

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route index path="/login" element={<LoginPage />} />
            <Route index path="/servico-automacoes" element={<Automacoes />} />
            <Route index path="/servico-desenvolvimento" element={<Desenvolvimento />} />
            <Route index path="/servico-suporte" element={<Suporte />} />
            <Route index path="/dashboard" element={<Dashboard />} />
            <Route index path="/profile" element={<Profile />} />

          </Routes>
        </Router>

    </div >
  );
}

export default App;
